<template>
  <v-app>
    <div class="reserve-form">
      <h3 class="reserv-title">Password Reset Form</h3>
      <v-form ref="form" @submit="reset" v-model="valid" :lazy-validation="lazy" class="pa-3 mt-65">
        <v-text-field
          v-model="user.email"
          :rules="[
                v => !!v || 'Email is required',
                v => /.+@.+\..+/.test(v) || 'Email must be valid',]"
          label="Email"
          outlined
          dense
          required
        ></v-text-field>

        <v-btn
          small
          block
          :disabled="!valid"
          style="color:#fff"
          color="deep-orange darken-2"
          @click="reset()"
          :loading="loading"
        >Reset</v-btn>
        <p class="float-left mt-2 f-z">
          <router-link :to="{name: 'signIn'}">
            or
            <span style="color:#e54c2a">Log In</span>
          </router-link>
        </p>
      </v-form>
      <notifications group="resetMail" position="top left" />
      <v-snackbar v-model="snackbar">
        {{ text }}
        <v-btn color="pink" text @click="snackbar = false">Close</v-btn>
      </v-snackbar>
    </div>
  </v-app>
</template>

<script>
export default {
  name: "signIn",
  data() {
    return {
      lazy: false,
      alert: false,
      text: "",
      disabled: false,
      valid: true,
      loading: false,
      snackbar: false,
      user: {
        email: "",
      },
      products: this.$store.state.cart,
    };
  },

  computed: {
    total: function () {
      return this.$store.state.cart.length;
    },
  },
  methods: {
    reset() {
      this.loading = true;
      this.axios
        .post("/password/email", { email: this.user.email })
        .then((response) => {
          //console.log(response);
          let logRes = response.data.success;
          if (logRes == false) {
            this.loading = false;
            (this.snackbar = true), (this.text = response.data.message);
          } else {
            this.$notify({
              group: "resetMail",
              type: "success",
              title: response.data.message,
              //text: 'Hello user! This is a notification!'
            });
            this.user.email = "";
            this.loading = false;
          }
        });
    },
  },
  mounted() {
    var el = document.querySelector(".v-application--wrap");
    el.classList.add("mystyle2");
  },
};
</script>

<style scoped>
.router-link-exact-active {
  color: #dbb664 !important;
}
.reserv-title {
  margin-top: 80px;
}
.theme--light.v-application {
  background: transparent;
  color: rgba(0, 0, 0, 0.87);
}
.f-z {
  font-size: 14px;
}
@media only screen and (max-width: 768px) {
  .reserv-title {
    margin-top: 10px;
  }
}
</style>